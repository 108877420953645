import React from 'react';
import styled from "styled-components";
import media from 'styled-media-query';

export const HomeHeader = () => {

  return (
    <>
      <SContainer>
        <Stitle>
          Nauti's Shippo ART<br />
          ノーチのしっぽ研究所
        </Stitle>
        <SContent>
          理系出身のイラストレーター。<br />
          生き物や科学系のイラストを得意とし、<br />
          子供向け書籍の挿絵から<br />
          サイエンスイラストレーションまで、<br />
          幅広いジャンルを手がける。<br />
          絵本作家として、架空の研究所<br />
          「ノーチのしっぽ研究所」を舞台にした<br />
          オリジナル作品も発表中。
        </SContent>
      </SContainer>
    </>
  );
}

const SContainer = styled.div`
  text-align: center;
  height: 80vh;
  min-height: 500px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const Stitle = styled.h1`
  font-size: 36px;
  letter-spacing: .1em;
  ${media.lessThan("small")`
   font-size: 24px;
  `}
`
const SContent = styled.p`
  margin: 30px auto 0 auto;
  font-size: 12px;
  letter-spacing: .1em;
  line-height: 24px;
  color: #325064;
  width: 90%;
  max-width: 500px;
`