import React from 'react';
import { Router } from "./components/router/Router";

// mapでダミーデータを作成 => 32.Pageの作成を参照
function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
