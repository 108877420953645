import React from 'react';
import styled from "styled-components";
import media from "styled-media-query";
import { AboutCard } from "../organisms/about/AboutCard";
import AboutImage from "./img/about.jpg";

const history = [
  {
    title: '千葉県に生まれる',
    startDate: '1997/1',
    content: `自然に囲まれた農家で育ち、生き物に興味を持つ。`,
  },
  {
    title: '色鉛筆画家として活動を開始',
    startDate: '2018/8',
    content: `SNSや展示会で作品を発表し始める。`,
  },
  {
    title: '日本理科美術協会に入会',
    startDate: '2018/12',
    content: `サイエンスイラストレーターが所属する団体。本名の石毛洋輔名義で入会し、現在に至る。`,
  },
  {
    title: '千葉大学理学部を卒業',
    startDate: '2019/3',
    content: `生き物が形作られるメカニズムを学びたいと考え、大学では生物学を専攻。`,
  },
  {
    title: 'ノーチのしっぽ研究所名義でのアーティスト活動を開始',
    startDate: '2020/5',
    content: `オリジナルの世界観を創作。イラスト以外の絵本、マンガ、アニメ等の表現手法にも挑戦し始める。`,
  },
  {
    title: '初の個展を開催',
    startDate: '2021/7',
    content: `オリジナルストーリーに沿った展示を企画し、自主制作絵本の原画等を発表。期間中にワークショップを開催し、色鉛筆画講師としてもデビュー。`,
  },
  {
    title: 'オンライン講座の講師を務める',
    startDate: '2021/7',
    content: `画材メーカーの株式会社オリオン主催のもの。ライブ配信と動画配信の2形式があり、教材制作も行う。（〜2022/12）`,
  },
  {
    title: '本格的にイラストレーター活動を開始',
    startDate: '2022~',
    content: `アーティストとして創作を続けつつ、科学系を中心に多様なジャンルのイラストを制作。`,
  },
]

const evaluation = [
  {
    title: '日本テレビ「ヒルナンデス！」出演',
    startDate: '2019/4',
    content: `アートの達人コーナーで色鉛筆画家として紹介され、作品をスタジオ披露。`,
  },
  {
    title: '東京ホビーショウ2019＠東京ビッグサイト',
    startDate: '2019/4',
    content: `色鉛筆メーカーDERWENTブースにてライブドローイング。`,
  },
  {
    title: '『第１回 in the Room展』スケッチブック商品化賞',
    startDate: '2020/5',
    content: `作品がスケッチブックの表紙を飾り、全国の画材店で販売された。`,
  },
  {
    title: '『第２回 in the Room展』フレームA賞',
    startDate: '2020/10',
    content: ``,
  },
]

export const About = () => {
  return (
    <>
      <STitleContainer>
        <STitle>About</STitle>
      </STitleContainer >
      <SImgContainer>
        <SImg src={AboutImage} alt="about"></SImg>
      </SImgContainer>
      <SContainer>
        <AboutCard step={history}></AboutCard>
      </SContainer>
      <STitleContainer>
        <STitle>Media / Evaluation</STitle>
      </STitleContainer>
      <SContainer>
        <AboutCard step={evaluation}></AboutCard>
      </SContainer>
    </>
  );
}

const STitleContainer = styled.div`
  padding: 24px 0 0 0;
  text-align: center;
`
const STitle = styled.h2`
  letter-spacing: .1em;
  color: #00828C;
  ${media.greaterThan("large")`
    width: 100%;
    margin: 0;
  `}
`
const SImgContainer = styled.div`
  max-width: 1170px;
  margin: 24px auto 0;
  text-align: center;
`
const SImg = styled.img`
  width: 80%;
  height: 80%;
`
const SContainer = styled.div`
  max-width: 1170px;
  margin: 12px 0 120px;
  position: relative;
  ::before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 105%;
    background: #AF6423;
    position: absolute;
    left: 30px;
  }
  ${media.greaterThan("large")`
    margin: 64px auto;
    padding: 0 32px;
    ::before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  `}
`