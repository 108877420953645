import React from 'react';
import styled from 'styled-components';
import media from "styled-media-query";
import { SLi, SUl } from './IconCss';

export const IconHeader = () => {
  return (
    <>
      <SIcons>
      <SIconLink><a href='https://www.youtube.com/@nautisshippolab.3431'target='_blank' rel="noreferrer"><i className="fa-brands fa-youtube" style={{color: "#00828C",}}></i></a></SIconLink>
      <SIconLink><a href='https://www.instagram.com/nautis_shippo/'target='_blank' rel="noreferrer"><i className="fa-brands fa-instagram" style={{color: "#00828C",}}></i></a></SIconLink>
      <SIconLink><a href='https://twitter.com/nautis_shippo'target='_blank' rel="noreferrer"><i className="fa-brands fa-x-twitter" style={{color: "#00828C",}}></i></a></SIconLink>
      </SIcons>
    </>
  );
}

const SIcons = styled(SUl)`
  position: absolute;
  left: 0;
  width: 17px;
  height: 44px;
  ${media.lessThan("small")`
    display: none;
  `}
`
const SIconLink = styled(SLi)`
  ${media.lessThan("medium")`
    margin: 0 0 0 5px;
  `}
`