import React from 'react';
import styled from "styled-components";
import media from 'styled-media-query';
import { LinkButton } from '../../atoms/button/TopButton';
import bgAbout from '../../pages/img/top_about.jpg';
import bgWorks from '../../pages/img/top_works.jpg';
import bgContact from '../../pages/img/top_contact.jpg';

export const HomeContents = (props) => {
  const { menus } = props;
  return (
    <>
      {
        React.Children.toArray(menus.map((e) => (
          // 条件分岐で１つ目２つ目とかで分けるか
          <SContentsContainer>
            {
              e.type === "right" ?
                (
                  e.title === "About" ?
                    (
                      <>
                        <>
                          <SContentTitle>{e.title}</SContentTitle>
                        </>
                        <SContentAbout >
                          <SContentRight>
                            <LinkButton url={e.url}>VIEW MORE</LinkButton>
                          </SContentRight>
                        </SContentAbout>
                      </>
                    )
                    :
                    (
                      <>
                        <>
                          <SContentTitle>{e.title}</SContentTitle>
                        </>
                        <SContentContact >
                          <SContentRight>
                            <LinkButton url={e.url}>VIEW MORE</LinkButton>
                          </SContentRight>
                        </SContentContact>
                      </>
                    )

                )
                :
                (
                  <>
                    <>
                      <SContentTitle>{e.title}</SContentTitle>
                    </>
                    <SContentWorks>
                      <SContentLeft>
                        <LinkButton url={e.url}>VIEW MORE</LinkButton>
                      </SContentLeft>
                    </SContentWorks>
                  </>
                )
            }
          </SContentsContainer>
        )))
      }
    </>
  );
}

const SContentsContainer = styled.div`

  width: 80%;
  max-width: 1000px;
  height: 60vh;
  min-height: 60vh;
  max-height: 500px;
  margin: 0 auto;
  position: relative;

  :nth-of-type(1) {
  margin: -132px auto 0 auto;
  }
  :nth-of-type(2) {
  margin: -60px auto 0 auto;
  }
  :nth-of-type(3) {
  margin: -60px auto 0 auto;
  }
`

const SContentTitle = styled.p`
  text-decoration: none;
  display:block;
  margin: 60px auto 0px auto;
  width: fit-content;
  font-size: 36px;
  font-weight: 400;
`
const SContentContainer = styled.div`
  border-radius: 100px;
  border: solid #325064;
  width: 100%;
  height: 60%;
  margin: 36px auto 0px auto;
  background-size: cover;
  background-position: center;
`
const SContentAbout = styled(SContentContainer)`
  background-image: url('${bgAbout}');
`
const SContentWorks = styled(SContentContainer)`
  background-image: url('${bgWorks}');
`
const SContentContact = styled(SContentContainer)`
  background-image: url('${bgContact}');
`
const SContent = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.lessThan("small")`
   width: 100%;
  `}
`
const SContentLeft = styled(SContent)`
`
const SContentRight = styled(SContent)`
margin: 0 0 0 auto;
`