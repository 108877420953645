import React from 'react';
import styled from "styled-components";
import Lightbox from "lightbox-react";
import "lightbox-react/style.css";
import ClientA01 from '../../pages/img/client_A_01.jpg'
import ClientA02_1 from '../../pages/img/client_A_02-1.jpg'
import ClientB01 from '../../pages/img/client_B_01.jpg'
import ClientB02 from '../../pages/img/client_B_02.jpg'
import ClientB03 from '../../pages/img/client_B_03.jpg'
import ClientC01 from '../../pages/img/client_C_01.jpg'
import ClientC02 from '../../pages/img/client_C_02.jpg'
import ClientC03 from '../../pages/img/client_C_03.jpg'
import ClientC04 from '../../pages/img/client_C_04.jpg'
import OriginalA01 from '../../pages/img/original_A_01.jpg'
import OriginalA02 from '../../pages/img/original_A_02.jpg'
import OriginalA03 from '../../pages/img/original_A_03.jpg'
import OriginalA04 from '../../pages/img/original_A_04.jpg'
import OriginalB01 from '../../pages/img/original_B_01.jpg'
import OriginalB02 from '../../pages/img/original_B_02.jpg'
import OriginalC01 from '../../pages/img/original_C_01.jpg'
import OriginalC02 from '../../pages/img/original_C_02.jpg'
import OriginalC03 from '../../pages/img/original_C_03.jpg'
import OriginalC04 from '../../pages/img/original_C_04.png'
import OriginalC05 from '../../pages/img/original_C_05.png'

export const ModalImage = (props) => {
  const { showImage, setShowImage, workType, workGenre, worksIndex, setWorksIndex } = props;

  var worksTemp = ""
  var titlesTemp = ""
  var captionsTemp = ""

  const worksCA = [
    ClientA01,
    ClientA02_1,
  ]
  const titlesCA = [
    "ふなばし三番瀬環境学習館 特別展",
    "ふなばし三番瀬環境学習館 特別展",
  ]
  const captionsCA = [
    "【2022年 夏の特別展】ポスター（メインビジュアル）のイラスト・デザイン、展示物イラストを担当。",
    "【2021年 春の特別展】ポスターイラスト、展示物イラストを担当。"
  ]

  const worksCB = [
    ClientB01,
    ClientB02,
    ClientB03,
  ]
  const titlesCB = [
    "柳田理科雄著『空想科学昆虫図鑑 もし虫が人間サイズだったら？』",
    "柳田理科雄著『空想科学昆虫図鑑 もし虫が人間サイズだったら？』",
    "柳田理科雄著『空想科学昆虫図鑑 もし虫が人間サイズだったら？』"
  ]
  const captionsCB = [
    "【冒頭見開き８Pの一部】構成・イラストを担当。虫が人間と同じサイズになった空想世界を描いた導入のページ。",
    "【冒頭見開き８Pの一部】世代交代のサイクルが早い昆虫の進化的戦略を子どもたちにもわかりやすいよう図解した。",
    "【本文中イラスト】渡りを行う蝶、アサギマダラの驚くべき飛行能力を説明したイラスト。",
  ]

  const worksCC = [
    ClientC01,
    ClientC02,
    ClientC03,
    ClientC04,
  ]
  const titlesCC = [
    "「大迫力」シリーズ",
    "「大迫力」シリーズ",
    "「大迫力」シリーズ",
    "「大迫力」シリーズ",
  ]
  const captionsCC = [
    "『大迫力！戦慄の都市伝説大百科』縄文時代に作られた遮光器土偶は宇宙人がモデルだったのでは、という都市伝説を描いた。",
    "『大迫力！戦慄の都市伝説大百科』月面観測衛星が捉えた人型の影をもとにした都市伝説を描いた。",
    "『大迫力！NEO 伝説の武器・刀剣・防具大図鑑』ギリシャ神話に登場する魔女キルケが、人間を魔法薬で豚に変えたという伝説を描いた。",
    "『大迫力！NEO 伝説の武器・刀剣・防具大図鑑』コラムページ内のイラストも複数担当。",
  ]

  const worksOA = [
    OriginalA01,
    OriginalA02,
    OriginalA03,
    OriginalA04,
  ]
  const titlesOA = [
    "絵と物語",
    "絵と物語",
    "絵と物語",
    "絵と物語",
  ]
  const captionsOA = [
    "『冒険のはじまり』空を見上げると、深い青の中に伸びたすじ雲を、小さな影が横切っていた。なんだか今日は大発見が待っている予感がした。（色鉛筆、マーカー）",
    "『ノーチのしっぽ研究所へようこそ！』研究所の建物は、かつてこの島に流れ着いた海賊船を改装したものだ。展望台からの景色は最高だ。――どうだね、少し見ていくかね？（アクリル）",
    "『見よ、探検家は還った』１羽の勇気ある探検家は世界中を旅し、彼女の勇姿は肖像画に描かれた。…というのは全て彼女の妄想なのである。（色鉛筆、マーカー）",
    "『生物学全景図 パノラマばいおろじい』生物学の学問分野の広さについて紹介したイラスト。「博物ふぇすてぃばる！」にて発表・配布。（デジタル）",
  ]

  const worksOB = [
    OriginalB01,
    OriginalB02,
  ]
  const titlesOB = [
    "動物画",
    "動物画",
  ]
  const captionsOB = [
    "『カワセミのハンティング』水中での躍動や羽の構造色など、写真では捉えきれない美しさの表現を試みた作品。（色鉛筆）",
    "『新旧ドードーの比較』文献を読んでドードー復元の新旧の解釈を比較し、自分なりの解釈を加えて描いた復元画。（透明水彩）"
  ]

  const worksOC = [
    OriginalC01,
    OriginalC02,
    OriginalC03,
    OriginalC04,
    OriginalC05,
  ]
  const titlesOC = [
    "書籍",
    "書籍",
    "書籍",
    "書籍",
    "書籍",
  ]
  const captionsOC = [
    "絵本『トロポコの冒険記録』動物の研究員たちの冒険を通して自然科学の面白さを伝えた自主制作絵本。",
    "絵本『トロポコの冒険記録』本文抜粋。柱状節理ができる原理を解説したページ。",
    "絵本『トロポコの冒険記録』本書に登場する架空の研究所マップ。視覚探索的な遊びを取り入れた。",
    "画集第１刊『まわりにあるもの』オリジナルイラストにストーリーを添えた作品集。",
    "画集第１刊『まわりにあるもの』本文抜粋。動物の研究員たちが発刊した科学ジャーナルという体裁を取っている。",
  ]

  if (workType === "Client") {
    if (workGenre === "A") {
      worksTemp = worksCA
      titlesTemp = titlesCA
      captionsTemp = captionsCA
    } else if (workGenre === "B") {
      worksTemp = worksCB
      titlesTemp = titlesCB
      captionsTemp = captionsCB
    } else if (workGenre === "C") {
      worksTemp = worksCC
      titlesTemp = titlesCC
      captionsTemp = captionsCC
    }
  } else if (workType === "Original") {
    if (workGenre === "A") {
      worksTemp = worksOA
      titlesTemp = titlesOA
      captionsTemp = captionsOA
    } else if (workGenre === "B") {
      worksTemp = worksOB
      titlesTemp = titlesOB
      captionsTemp = captionsOB
    } else if (workGenre === "C") {
      worksTemp = worksOC
      titlesTemp = titlesOC
      captionsTemp = captionsOC
    }
  }

  const works = worksTemp
  const titles = titlesTemp
  const captions = captionsTemp

  if (showImage) {
    return (

      <SModalBackground>
        <SModalContainer>
          <Lightbox
            mainSrc={`${works[worksIndex]}`}
            nextSrc={works[(worksIndex + 1) % works.length]}
            prevSrc={works[(worksIndex + works.length - 1) % works.length]}
            onCloseRequest={() => setShowImage(false)}
            onMovePrevRequest={() =>
              setWorksIndex((worksIndex + works.length - 1) % works.length)
            }
            onMoveNextRequest={() => setWorksIndex((worksIndex + 1) % works.length)}
            imageTitle={titles[worksIndex]}
            imageCaption={captions[worksIndex]}
          />
        </SModalContainer>
      </SModalBackground>

    );
  } else {
    return null;
  }
}


// モーダル
const SModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:rgba(0,0,0,0.5);

  /*　画面の中央に要素を表示させる設定　*/
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`

const SModalContainer = styled.div`
  z-index: 99999;
  width: 80%;
  max-width: 800px;
  height: auto;
  max-height: 800px;
  padding: 1em;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`