import React from 'react';
import styled from 'styled-components';
import { HomeHeader } from '../organisms/home/HomeHeader';
import { HomeContents } from '../organisms/home/HomeContents';

const menus = [
  {
    type: "right",
    title:"About",
    url:"/about",
  },
  {
    type: "left",
    title:"Works",
    url:"/works",
  },
  {
    type: "right",
    title:"Contact",
    url:"/contact",
  },
]

export const Home = () => {
  return (
    <>
     <HomeHeader></HomeHeader>
      <SMainContainer>
        <HomeContents menus={menus}></HomeContents>
      </SMainContainer>
    </>
  );
}

const SMainContainer = styled.div`
`
