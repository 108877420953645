import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { LinkButton } from '../atoms/button/WorksButton';
import { ModalImage } from "../organisms/works/ModalImg";
import { RedirectToNote } from "../organisms/works/OpenLink";
import thumnailClientA from './img/square_client_A_01.jpg'
import thumnailClientB from './img/square_client_B_01.jpg'
import thumnailClientC from './img/square_client_C_01.jpg'
import thumnailClientD from './img/square_client_D_01.jpg'
import thumnailOriginalA from './img/square_original_A_01.jpg'
import thumnailOriginalB from './img/square_original_B_01.jpg'
import thumnailOriginalC from './img/square_original_C_02.jpg'


const clientWorks = [
  {
    url: "#",
    title: "ふなばし三番瀬環境学習館 特別展",
    comment: "学習館監修のもと、ポスターと展示物イラストを制作。",
    background: thumnailClientA,
    workType: "Client",
    workGenre: "A",
  },
  {
    url: "#",
    title: "柳田理科雄著『空想科学昆虫図鑑     もし虫が人間サイズだったら？』",
    comment: "キャラクターデザイン、一部挿画を担当。（西東社出版）",
    background: thumnailClientB,
    workType: "Client",
    workGenre: "B",
  },
  {
    url: "#",
    title: "「大迫力」シリーズ",
    comment: "武器・都市伝説等様々なジャンルを取り上げた児童向けの図鑑シリーズ。一部タイトルで挿画を担当。（西東社出版）",
    background: thumnailClientC,
    workType: "Client",
    workGenre: "C",
  },
  {
    url: "https://note.com/nautis_shippo/n/na2e2e8b5c8a6",
    title: "国立国際医療研究センター監修      「マンガでわかるシスチノーシス」",
    comment: "希少疾患の患者やその家族を対象とした解説マンガ。資料やヒアリング内容を元に、ストーリーから制作。",
    background: thumnailClientD,
    workType: "Client",
    workGenre: "D",
  },
]

const originalWorks = [
  {
    url: "#",
    title: "絵と物語/ illustration and stories",
    comment: "不思議な島々「ノーチラス諸島」で暮らす動物の研究員たちの物語を制作。",
    background: thumnailOriginalA,
    workType: "Original",
    workGenre: "A",
  },
  {
    url: "#",
    title: "動物画/ animal illustration",
    comment: "生き物の美しさを再現したリアルタッチの絵画も多数制作。",
    background: thumnailOriginalB,
    workType: "Original",
    workGenre: "B",
  },
  {
    url: "#",
    title: "書籍/ books",
    comment: "絵本や作品集を発表。ストーリー執筆にも力を入れている。",
    background: thumnailOriginalC,
    workType: "Original",
    workGenre: "C",
  },
]



export const Works = () => {

  const [showImage, setShowImage] = useState(false);
  const [worksIndex, setWorksIndex] = useState(0);
  const [workType, setWorkType] = useState();
  const [workGenre, setWorkGenre] = useState();

  return (
    <>
      <SContainer>
        <STitle>Client Works</STitle>
        <SWorks>
          {
            React.Children.toArray(clientWorks.map((f, i) => {

              return (
                <>{
                  f.background === thumnailClientD ?
                    (
                      <SWork src={f.background}>
                        <SWorkTitleContainer>
                          <SWorkTitle>{f.title}</SWorkTitle>
                        </SWorkTitleContainer>
                        <SCommentContainer>
                          <SComment>{f.comment}</SComment>
                        </SCommentContainer>
                        <SImg src={f.background} />
                        <SMaskContainer>
                          <SMakingComment>
                            <SMaskingPDF>全文をお読みいただけます。</SMaskingPDF>
                            <RedirectToNote />
                          </SMakingComment>
                        </SMaskContainer>
                      </SWork >
                    ) :
                    (
                      <SWork src={f.background} onClick={() => { setShowImage(true); setWorkType(f.workType); setWorkGenre(f.workGenre) }}>
                        <SWorkTitleContainer>
                          <SWorkTitle>{f.title}</SWorkTitle>
                        </SWorkTitleContainer>
                        <SCommentContainer>
                          <SComment>{f.comment}</SComment>
                        </SCommentContainer>
                        <SImg src={f.background} />
                        <SMaskContainer>
                          <SMakingComment>
                            <LinkButton url={f.url}>VIEW MORE</LinkButton>
                          </SMakingComment>
                        </SMaskContainer>
                      </SWork >
                    )
                }
                  <ModalImage showImage={showImage} setShowImage={setShowImage} workType={workType} setWorkType={setWorkType} workGenre={workGenre} setWorkGenre={setWorkGenre} worksIndex={worksIndex} setWorksIndex={setWorksIndex}></ModalImage>
                </>
              );
            }))
          }
        </SWorks>
        <STitle>Original Works</STitle>
        <SWorks>
          {
            React.Children.toArray(originalWorks.map((g, i) => {
              return (
                <>
                  <SWork  src={g.background} onClick={() => { setShowImage(true); setWorkType(g.workType); setWorkGenre(g.workGenre) }}>
                    <SWorkTitleContainer>
                      <SWorkTitle>{g.title}</SWorkTitle>
                    </SWorkTitleContainer>
                    <SCommentContainer>
                      <SComment>{g.comment}</SComment>
                    </SCommentContainer>
                    <SImg src={g.background} />
                    <SMaskContainer>
                      <SMakingComment>
                        <LinkButton url={g.url}>VIEW MORE</LinkButton>
                      </SMakingComment>
                    </SMaskContainer>
                  </SWork >
                  <ModalImage showImage={showImage} setShowImage={setShowImage} workType={workType} setWorkType={setWorkType} workGenre={workGenre} setWorkGenre={setWorkGenre} worksIndex={worksIndex} setWorksIndex={setWorksIndex}></ModalImage>
                </>
              );
            }))
          }

        </SWorks>
      </SContainer >
    </>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`
const SWorks = styled.div`
  margin: 20px 0 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  justify-content: center;
`
const SWork = styled.div`
  margin: 0 20px 70px;
  max-width: 400px;
  position:		relative;
`
const SWorkTitleContainer = styled.div`
  height: 84px;
`
const SWorkTitle = styled.h3`
  letter-spacing: .1em;
  margin: 12px 0 0 12px;
  white-space: pre-wrap;
`
const SCommentContainer = styled.div`
  height: 60px;
`
const SComment = styled.div`
  font-size: 12px;
  letter-spacing: .1em;
  margin: 12px 0 0 12px;
`
const SImg = styled.img`
  width: 100%;
  transition: .2s;
  &:hover {
    cursor: pointer;
  }
`
const SMaskContainer = styled(Link)`
  width: 100%;
  aspect-ratio: 1/1;
  position: absolute;
  top: 168px;
  left: 0;
  background-color:	rgba(0,0,0,0.3);
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
    

  ${media.greaterThan("large")`
    width: 100%;
    aspect-ratio: 1/1;
    position: absolute;
    top: 168px;
    left: 0;
    opacity: 0;
    background-color:	rgba(0,0,0,0.3);
    color: #fff;
    text-decoration: none;

    display: flex;
    align-items: flex-end;

    &:hover {
      opacity: .3;
    }
  `}
`
const SMakingComment = styled.div`
  width: 100%;
  height: 100%;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`
const SMaskingPDF = styled.div`
width: 100%;
text-align: center;
`

