import React from "react";
import { LinkButton } from '../../atoms/button/WorksButton';

export function RedirectToNote() {
  const redirectToNote = () => {
    window.open('https://note.com/nautis_shippo/n/na2e2e8b5c8a6', '_blank')
  };
  return (
      <LinkButton >
        <div onClick={redirectToNote}>noteへ</div>
      </LinkButton>
  );
}